<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_user()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Szczegóły</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <v-text-field
                  v-model="user_data.name"
                  label="Nazwa"
                  color="primary"
                ></v-text-field>
               
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <Permissions 
          v-if="loaded"
          :id="id"
          @selected="update_selected"
        />

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import Permissions from '@/components/Role/Permissions.vue';
export default {
  components: {
    Permissions
  },
  data: () => ({
    id: 0,
    loaded: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista ról',
        disabled: false,
        to: '/role',
      },
      {
        text: 'Rola',
        disabled: true,
        to: '/rola',
      }
    ],

    roles: {
      head: [],
      project: [],
      sprint: [],
      task: [],
      invoice: [],
      file: [],
      issue: [],
      issue_comment: [],
      issue_users: [],
      package: [],
      message: [],
      message_comments: [],
      category_faq: [],
      faq: [],
      faqcategory: []      
    },

    selected_permissions: [],
    user_data: {
      id: 1,
      name: 'Andrzej Maślak z ZOO',
    },
    
    search: ''
  }),
  methods: {
    update_selected(value){
      console.log(value);
      this.selected_permissions = value;
    },
    edit_user() {

      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.user_data.name);
      for(let index in this.selected_permissions){
        formData.append("permission_id["+index+"]", this.selected_permissions[index]);
      }
      if(!this.selected_permissions.length) formData.append("clear", true);

      // formData.append("role", this.selected_permissions);

      this.$axios({url: this.$store.state.api +'/auth/role/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/role');
          this.$store.commit("snackbar", {
            text: "Wprowadzono zmiany",
            color: "primary",
            btn_color: "white"
          });
          console.log(resp.data);
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    
  },
  mounted(){
    
    this.id = parseInt(this.$route.params.id);
    console.log('MAM ID: '+this.id)
    this.loaded=true;

    this.$axios({url: this.$store.state.api +'/auth/role/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data;
        // Tworzenie listy ID przyznanych uprawnien dla uzytkownika
        // for(let item in resp.data.permissions) {
        //   this.selected_permissions.push(resp.data.permissions[item].permission_id);
        // }
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.classic_select {
  width: 250px;
}
</style>